import loadable from '@loadable/component'
import {
    Storefront,
    Smartphone,
    LocalShippingOutlined,
} from '@mui/icons-material'
import {
    Grid,
    Box,
    Avatar,
    Divider,
    Paper,
    BottomNavigation,
    BottomNavigationAction as _BottomNavigationAction,
    useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import { graphql, Link as GatsbyLink, navigate } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { StaticImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import React, { useCallback, useMemo } from 'react'
import { Only } from '../components/Breakpoint'
import { Guide } from '../components/Guide'
import { Layout } from '../components/Layout'
import { Link } from '../components/Link'
import {
    RingGallery,
    convertMarriageRing,
    convertEngageRing,
} from '../components/RingGallery'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'
import type { TopPageQuery } from '../../types/graphql-types'
import type { PageProps } from 'gatsby'

const Map = loadable(() => import('../components/Map'), { ssr: false })
const Movie = loadable(() => import('../components/Movie'), { ssr: false })

export const Head = () => (
    <SEO
        title="安い結婚指輪・婚約指輪なら高品質でも激安な指輪を探せるミスプラチナで"
        description="低価格のブライダルリングならメーカー直販のミスプラチナ。１万円～3万円台の結婚指輪や10万円以下の婚約指輪などを中心に激安セール中！プラチナでも相場の半額以下のお値段で通販、ご来店でもご利用頂けます♪"
        page="top"
    />
)

const IndexPage: React.FC<PageProps<TopPageQuery>> = ({ data }) => {
    const muiTheme = useTheme()
    const threeReasonsBgImage = convertToBgImage(
        data.file?.childImageSharp?.gatsbyImageData
    )

    const marriageRings = useMemo(() => {
        return data.marriageRings.edges.map((edge) => {
            const ring = edge.node
            if (Array.isArray(ring.images)) {
                ring.images = [ring.images[0]]
            }
            return convertMarriageRing(ring)
        })
    }, [data])
    const engageRings = useMemo(() => {
        return data.engageRings.edges.map((edge) => {
            const ring = edge.node
            if (Array.isArray(ring.images)) {
                ring.images = [ring.images[0]]
            }
            return convertEngageRing(edge.node)
        })
    }, [data])

    const goToReservation = useCallback(() => {
        window.gtag !== undefined && window.gtag('event', 'go_to_reservation')
    }, [])
    return (
        <Layout>
            <Grid container spacing={0} component="main">
                <Grid item sm={3} />
                <Grid item sm={6} xs={12}>
                    <TopWrapper theme={muiTheme}>
                        <CatchImgWrapper>
                            <Only device="pc">
                                <StaticImage
                                    width={440}
                                    src="../images/top/top.jpg"
                                    alt="メーカー直販による、高品質、最短納期、安心価格。自由にアレンジを加えてオリジナルのリングをオーダー。"
                                />
                            </Only>
                            <Only device="sp">
                                <StaticImage
                                    src="../images/top/top.jpg"
                                    alt="メーカー直販による、高品質、最短納期、安心価格。自由にアレンジを加えてオリジナルのリングをオーダー。"
                                />
                            </Only>
                        </CatchImgWrapper>
                        <TopRing to="/marriagering/">
                            <TopRingImage theme={muiTheme}>
                                <StaticImage
                                    src="../images/top/marriage_ring.jpg"
                                    alt=""
                                />
                            </TopRingImage>
                            <TopRingInfo>
                                <TopRingInfoRow>
                                    <TopRingBorderTxt size="xs">
                                        marriage
                                    </TopRingBorderTxt>
                                </TopRingInfoRow>
                                <TopRingInfoRow>
                                    <Txt bold>結婚指輪</Txt>
                                </TopRingInfoRow>
                                <TopRingInfoRow>
                                    <Txt size="xs">プラチナ950</Txt>
                                    <TopRingPriceTxt bold primary>
                                        19,800
                                    </TopRingPriceTxt>
                                    <Txt size="xs">円〜</Txt>
                                </TopRingInfoRow>
                                <TopRingInfoRow>
                                    <Txt size="xs">K5ゴールド</Txt>
                                    <TopRingPriceTxt bold primary>
                                        29,800
                                    </TopRingPriceTxt>
                                    <Txt size="xs">円〜</Txt>
                                </TopRingInfoRow>
                                <TopRingInfoRow>
                                    <TopRingBgTxt size="xs">
                                        通販OK
                                    </TopRingBgTxt>
                                    <TopRingBgTxt size="xs">
                                        来店OK
                                    </TopRingBgTxt>
                                </TopRingInfoRow>
                            </TopRingInfo>
                        </TopRing>
                        <TopRing to="/engagering/">
                            <TopRingImage theme={muiTheme}>
                                <StaticImage
                                    src="../images/top/engage_ring.jpg"
                                    alt=""
                                />
                            </TopRingImage>
                            <TopRingInfo>
                                <TopRingInfoRow>
                                    <TopRingBorderTxt size="xs">
                                        engagement
                                    </TopRingBorderTxt>
                                </TopRingInfoRow>
                                <TopRingInfoRow>
                                    <Txt bold>婚約指輪</Txt>
                                </TopRingInfoRow>
                                <TopRingInfoRow>
                                    <TopRingPriceTxt bold primary>
                                        39,800円
                                    </TopRingPriceTxt>
                                    <Txt size="xs">円〜</Txt>
                                </TopRingInfoRow>
                                <TopRingInfoRow>
                                    <TopRingBgTxt size="xs">
                                        通販OK
                                    </TopRingBgTxt>
                                    <TopRingBgTxt size="xs">
                                        来店OK
                                    </TopRingBgTxt>
                                </TopRingInfoRow>
                            </TopRingInfo>
                        </TopRing>
                        <StaticImage
                            src="../images/common/material.jpg"
                            alt=""
                        />
                        <GatsbyLink
                            to="/marriagering/"
                            aria-label="結婚指輪 | プラチナ950 19,800円~"
                        >
                            <StaticImage src="../images/top/a.jpg" alt="" />
                        </GatsbyLink>
                        <Movie />
                    </TopWrapper>
                </Grid>
                <Grid item xs={12}>
                    <ThreeReasonsBackgroundImage
                        Tag="section"
                        {...threeReasonsBgImage}
                    >
                        <ThreeReasonsWrapper theme={muiTheme}>
                            <ThreeReasonsInner theme={muiTheme}>
                                <ThreeReasonsTitle>
                                    <Txt primary bold>
                                        安心試着キャンペーン！
                                    </Txt>
                                </ThreeReasonsTitle>
                                <ThreeReasonsRow>
                                    <NumberWrapper>
                                        <Txt size="s">1</Txt>
                                    </NumberWrapper>
                                    <Txt size="s">プラチナ結婚指輪</Txt>
                                    <Txt size="s" primary>
                                        1万円台
                                    </Txt>
                                    <Txt size="s">～！</Txt>
                                </ThreeReasonsRow>
                                <ThreeReasonsRow>
                                    <NumberWrapper>
                                        <Txt size="s">2</Txt>
                                    </NumberWrapper>
                                    <Txt size="s">来店または</Txt>
                                    <Txt size="s" primary>
                                        試着リング無料配送
                                    </Txt>
                                    <Txt size="s">！</Txt>
                                </ThreeReasonsRow>
                                <ThreeReasonsRow>
                                    <NumberWrapper>
                                        <Txt size="s">3</Txt>
                                    </NumberWrapper>
                                    <Txt size="s">オーダーメイドも</Txt>
                                    <Txt size="s" primary>
                                        定額15万円
                                    </Txt>
                                    <Txt size="s">！</Txt>
                                </ThreeReasonsRow>
                            </ThreeReasonsInner>
                        </ThreeReasonsWrapper>
                    </ThreeReasonsBackgroundImage>
                    <H2>
                        <Txt size="l">結婚指輪</Txt>
                        <Txt size="s">をさがす</Txt>
                    </H2>
                    <CategorizeRings>
                        <RingDescription>
                            <Txt size="s">
                                デザイン豊富なプラチナ結婚指輪が１万円台～。
                            </Txt>
                            <Txt size="s">
                                華やかなダイヤリングも格安で取り揃えております。
                            </Txt>
                        </RingDescription>
                        <RingGallery rings={marriageRings} />
                    </CategorizeRings>
                    <MoreLinkWrapper>
                        <Link to="/marriagering/">結婚指輪をもっと見る</Link>
                    </MoreLinkWrapper>
                    <H2>
                        <Txt size="l">婚約指輪</Txt>
                        <Txt size="s">をさがす</Txt>
                    </H2>
                    <CategorizeRings>
                        <RingDescription>
                            <Txt size="s">
                                婚約指輪はダイヤ込み、鑑別書付き価格で3万円台～。
                            </Txt>
                            <Txt size="s">
                                グレードやデザインも幅広くご提案いたします。
                            </Txt>
                        </RingDescription>
                        <RingGallery rings={engageRings} />
                    </CategorizeRings>
                    <MoreLinkWrapper>
                        <Link to="/engagering/">婚約指輪をもっと見る</Link>
                    </MoreLinkWrapper>
                    <Guide />
                    <OrderGuideWrapper container spacing={0}>
                        <Grid item sm={2} xs={12} />
                        <Grid item sm={8} xs={12}>
                            <Only device="pc">
                                <Box sx={{ m: 1 }}>
                                    <StaticImage
                                        src="../images/top/order.jpg"
                                        alt="ご注文の流れ"
                                    />
                                </Box>
                            </Only>
                            <Only device="sp">
                                <H3>
                                    <Txt>ご注文の流れ</Txt>
                                </H3>
                                <StaticImage
                                    src="../images/common/order.jpg"
                                    alt=""
                                />
                            </Only>
                            <OrderGuide>
                                <OrderGuideTitle>
                                    <Avatar
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                            backgroundColor: '#3b4b5b',
                                            fontSize: '16px',
                                        }}
                                    >
                                        <Smartphone fontSize="inherit" />
                                    </Avatar>
                                    <OrderGuideTitleTxt size="s">
                                        WEBから購入される場合
                                    </OrderGuideTitleTxt>
                                </OrderGuideTitle>
                                <OrderGuideRow theme={muiTheme}>
                                    <NumberWrapper>
                                        <Txt size="s">1</Txt>
                                    </NumberWrapper>
                                    <Txt size="s">
                                        WEBから購入申込をしてください
                                    </Txt>
                                </OrderGuideRow>
                                <OrderGuideSubRow theme={muiTheme}>
                                    <Txt size="s">※申込前に郵送で</Txt>
                                    <Link to="/rental/">
                                        <Txt size="s">試着リング</Txt>
                                    </Link>
                                    <Txt size="s">
                                        をお送りすることも可能です。
                                    </Txt>
                                </OrderGuideSubRow>
                                <OrderGuideRow theme={muiTheme}>
                                    <NumberWrapper>
                                        <Txt size="s">2</Txt>
                                    </NumberWrapper>
                                    <Txt size="s">
                                        約2週間でご自宅にお届けします
                                    </Txt>
                                </OrderGuideRow>
                            </OrderGuide>
                            <OrderGuide>
                                <OrderGuideTitle>
                                    <Avatar
                                        style={{
                                            height: '20px',
                                            width: '20px',
                                            backgroundColor: '#3b4b5b',
                                            fontSize: '16px',
                                        }}
                                    >
                                        <Storefront fontSize="inherit" />
                                    </Avatar>
                                    <OrderGuideTitleTxt size="s">
                                        来店される場合
                                    </OrderGuideTitleTxt>
                                </OrderGuideTitle>
                                <OrderGuideRow theme={muiTheme}>
                                    <NumberWrapper>
                                        <Txt size="s">1</Txt>
                                    </NumberWrapper>
                                    <Txt size="s">
                                        miss
                                        platinum表参道ショールームまでお越しください
                                    </Txt>
                                </OrderGuideRow>
                                <OrderGuideRow theme={muiTheme}>
                                    <NumberWrapper>
                                        <Txt size="s">2</Txt>
                                    </NumberWrapper>
                                    <Txt size="s">
                                        デザインやサイズなどをお打ち合わせさせていただきます
                                    </Txt>
                                </OrderGuideRow>
                                <OrderGuideRow theme={muiTheme}>
                                    <NumberWrapper>
                                        <Txt size="s">3</Txt>
                                    </NumberWrapper>
                                    <Txt size="s">
                                        ご購入後、約2週間でご自宅にお届けします
                                    </Txt>
                                </OrderGuideRow>
                                <Divider />
                                <OrderGuideShop>
                                    <Txt size="s">
                                        miss platinum表参道ショールーム
                                    </Txt>
                                </OrderGuideShop>
                                <OrderGuideRow theme={muiTheme}>
                                    <Txt size="s">
                                        営業時間 : 9時〜17時（ご予約制）
                                    </Txt>
                                </OrderGuideRow>
                                <OrderGuideRow theme={muiTheme}>
                                    <Txt size="s">
                                        ご来社予約 : staff@missplatinum.net
                                    </Txt>
                                </OrderGuideRow>
                                <OrderGuideRow theme={muiTheme}>
                                    <Txt size="s">
                                        〒150-0001 東京都渋谷区神宮前3-16-16
                                    </Txt>
                                </OrderGuideRow>
                                <OrderGuideRow theme={muiTheme}>
                                    <Txt size="s">
                                        ※表参道駅徒歩８分/JR原宿駅徒歩10分
                                    </Txt>
                                </OrderGuideRow>
                                <MapWrapper>
                                    <Map />
                                </MapWrapper>
                            </OrderGuide>
                        </Grid>
                        <Grid item sm={2} xs={12} />
                    </OrderGuideWrapper>
                </Grid>
            </Grid>
            <Only device="sp">
                <FixedBottom elevation={8}>
                    <BottomNavigation showLabels>
                        <BottomNavigationAction
                            label="試着リング無料配送"
                            icon={<LocalShippingOutlined />}
                            onClick={() => navigate('/rental/')}
                            theme={muiTheme}
                        />
                        <BottomNavigationAction
                            label="ご来店予約"
                            icon={<Storefront />}
                            onClick={() => {
                                goToReservation()
                                location.href =
                                    'https://docs.google.com/forms/d/e/1FAIpQLSddBsiF-EVR_w8jf3fukTnZ2Wep-Y5VueK9-k08NX2MEkljCg/viewform'
                            }}
                            theme={muiTheme}
                        />
                    </BottomNavigation>
                </FixedBottom>
            </Only>
        </Layout>
    )
}

export const query = graphql`
    query TopPage {
        marriageRings: allMarriageringsJson(
            filter: { code: { in: ["1", "4", "13", "22"] } }
            sort: { fields: femalePrice, order: ASC }
        ) {
            edges {
                node {
                    code
                    name
                    material
                    femalePrice
                    malePrice
                    images
                }
            }
        }
        engageRings: allEngageringsJson(
            filter: { code: { in: ["88", "100", "108", "150", "264"] } }
            sort: { fields: price, order: ASC }
        ) {
            edges {
                node {
                    code
                    name
                    material
                    price
                    images
                }
            }
        }
        file(relativePath: { eq: "top/three_reasons.jpg" }) {
            childImageSharp {
                gatsbyImageData(formats: [WEBP])
            }
        }
    }
`
const CatchImgWrapper = styled('div')({
    minHeight: '276px',
})
const TopWrapper = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        padding: '20px',
    },
}))
const TopRing = styled(GatsbyLink)({
    display: 'flex',
    padding: '8px',
    margin: '20px 0 12px',
    textDecoration: 'none',
    color: 'inherit',
})
const TopRingImage = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '212px',
    [theme.breakpoints.down('md')]: {
        width: '160px',
    },
}))
const TopRingInfo = styled('div')({
    display: 'flex',
    flexDirection: 'column',
})
const TopRingInfoRow = styled('div')({
    marginBottom: '8px',
})
const TopRingBorderTxt = styled(Txt)({
    padding: '2px 4px',
    border: '1px solid #b1b7bd',
})
const TopRingPriceTxt = styled(Txt)({
    margin: '0 4px',
})
const TopRingBgTxt = styled(Txt)({
    padding: '2px 4px',
    backgroundColor: '#b1b7bd',
    color: '#ffffff',
    marginRight: '4px',
})
const ThreeReasonsBackgroundImage = styled(BackgroundImage)({
    backgroundPosition: 'top',
    padding: '16px 0',
    marginBottom: '20px',
})
const ThreeReasonsWrapper = styled('div')(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '8px',
    margin: '0 auto',
    width: '360px',
    [theme.breakpoints.down('md')]: {
        width: '300px',
    },
}))
const ThreeReasonsInner = styled('div')(({ theme }) => ({
    border: '1px solid #e2e3e8',
    padding: '12px 40px',
    [theme.breakpoints.down('md')]: {
        padding: '12px',
    },
}))
const ThreeReasonsTitle = styled('h2')({
    margin: '0 0 8px',
    textAlign: 'center',
})
const ThreeReasonsRow = styled('div')({
    marginBottom: '12px',
})
const H2 = styled('h2')({
    margin: '0 0 8px',
    lineHeight: 1,
    padding: '10px',
    borderBottom: '1px solid #aaaaaa',
})
const RingDescription = styled('p')({
    margin: '10px 10px 16px',
    display: 'flex',
    flexDirection: 'column',
})
const CategorizeRings = styled('div')({
    margin: '10px 10px 28px',
})
const MoreLinkWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'end',
    margin: '20px 10px',
})
const H3 = styled('h3')({
    margin: '0 0 4px',
    padding: '8px',
})
const OrderGuideWrapper = styled(Grid)({
    marginTop: '20px',
})
const OrderGuide = styled('div')({
    margin: '8px',
    padding: '8px 20px',
    backgroundColor: '#f8f8f9',
})
const OrderGuideTitle = styled('h4')({
    margin: '0 0 8px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})
const OrderGuideShop = styled('h5')({
    margin: '8px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})
const OrderGuideTitleTxt = styled(Txt)({
    marginLeft: '4px',
})
const OrderGuideRow = styled('div')(({ theme }) => ({
    marginBottom: '8px',
    [theme.breakpoints.up('sm')]: {
        marginRight: '120px',
        marginLeft: '120px',
    },
}))
const OrderGuideSubRow = styled('div')(({ theme }) => ({
    paddingLeft: '28px',
    marginTop: '-4px',
    marginBottom: '8px',
    [theme.breakpoints.up('sm')]: {
        marginRight: '120px',
        marginLeft: '120px',
    },
}))
const NumberWrapper = styled('div')({
    display: 'inline-block',
    textAlign: 'center',
    width: '20px',
    backgroundColor: '#b9bfc5',
    color: '#ffffff',
    fontWeight: 'bold',
    marginRight: '8px',
})
const MapWrapper = styled('div')({
    marginTop: '28px',
    width: '100%',
})
const FixedBottom = styled(Paper)({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 3,
})
const BottomNavigationAction = styled(_BottomNavigationAction)(({ theme }) => ({
    margin: '4px',
    borderRadius: '4px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
}))

export default IndexPage
